import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:id/:transactionId",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/HomeView.vue"),
    children: [
      {
        path: "",
        name: "support",
        component: () =>
          import(/* webpackChunkName: "support" */ "@/views/SupportView.vue"),
      },
      {
        path: "success",
        name: "success",
        component: () =>
          import(
            /* webpackChunkName: "success" */ "@/views/NotifySuccessView.vue"
          ),
      },
      {
        path: "fail",
        name: "fail",
        component: () =>
          import(/* webpackChunkName: "fail" */ "@/views/NotifyFailView.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/ErrorView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/error") {
    next();
    return;
  }

  const { id, transactionId } = to.params;

  // urlId와 Transaction Id가 존재하는지 확인
  if (!id || !transactionId) {
    next({ name: "error" });
    return;
  }

  // urlId 유효성 검사: 64자 소문자와 숫자로 구성된 SHA-256 형식
  const isValidId = /^[a-f0-9]{64}$/.test(id as string);

  // TODO: Transaction Id 유효성 검사 필요 시 추가

  if (!isValidId) {
    next({ name: "error" });
  } else {
    next();
  }
});

export default router;
